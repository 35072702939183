import React from 'react'

import Auth0ProviderWrapper from 'auth/providers/Auth0ProviderWrapper'
import { FlagsProvider, FlagsSingleton } from 'flags'
import { createBrowserHistory } from 'history'
import { auth0Settings } from 'lib/config/auth0Settings'
import { UserContextProvider } from 'lib/UserContext/provider'
import { Logger } from 'logging'
import { render } from 'react-dom'
import { initAmpAndElmo } from 'secondstepVideo'

import AppWrapper from './components/AppWrapper'
import { LD_CONFIG, USER_CONTEXT_URL } from './lib/config'
import flagsAppLevelSubscribes from './lib/config/flagsAppLevelSubscribes'
import flagsLocalDevFlagOverride from './lib/config/flagsLocalDevFlagOverride'
import './index.css'

Logger.init()
initAmpAndElmo()
const history = createBrowserHistory()
const ROOT = 'root'
const init = async () => {
  if (window.parent.__GOOGLE_ANALYTICS_FILTER_PROPERTIES__) {
    const currentPage = `/player/${window.parent.__GOOGLE_ANALYTICS_FILTER_PROPERTIES__.title}`
    Logger.logPageView(currentPage)
  }

  const rootElement = document.getElementById(ROOT)
  const { user, clientSideID } = LD_CONFIG
  FlagsSingleton.initialize({
    clientOptions: {
      user,
      options: {},
      clientID: clientSideID,
    },
    appLevelSubscribedFlags: flagsAppLevelSubscribes,
    localDevFlagOverride: flagsLocalDevFlagOverride,
  })
  await FlagsSingleton.waitForFlagSystemReady()

  if (window.parent.__CFC_PLAYER_DATA__?.learner) {
    const updatedUserConfigForLaunchDarkly = {
      userEmail: window.parent.__CFC_PLAYER_DATA__.learner,
      roles: ['Teacher'],
    }

    await FlagsSingleton.updateUserContext(updatedUserConfigForLaunchDarkly)
  }

  const userContextOptions = {
    baseUrl: `${USER_CONTEXT_URL}context`, // for example: https://api-np-dev.cfctest.org/context
  }

  render(
    <FlagsProvider softReload={true}>
      <UserContextProvider options={userContextOptions}>
        <Auth0ProviderWrapper
          {...auth0Settings}
          history={history}
          className="auth0-provider-wrapper"
        >
          <AppWrapper />
        </Auth0ProviderWrapper>
      </UserContextProvider>
    </FlagsProvider>,
    rootElement,
  )
}

init()
